
import { StoreEntityType } from 'ngx-unificator/store';
import { PaymentAction, PaymentMethod } from '../vendor/payment-v3/platform-payment-types';

export interface PaymentStore {
  currency: string;
  paymentList: PaymentMethod[];
  selectedMethod: PaymentMethod;
  action: PaymentAction;
}

export const paymentStore: StoreEntityType<PaymentStore> = {
  currency: null,
  paymentList: [],
  action: null,
  selectedMethod: null,
};

export type PaymentStoreEntity = typeof paymentStore;
