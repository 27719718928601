import { paymentStore } from "./payment.store";

export const GlobalStore = {
  PAYMENT_STORE: paymentStore,
};

export enum GlobalStoreName {
  PAYMENT_STORE = 'PAYMENT_STORE',
}

export type GlobalStoreNameType = keyof typeof GlobalStore;

export type GlobalStoreType = typeof GlobalStore;
